/* Contact Section */
#contact{
    padding: 1em 0;
}
.contact-section{
    margin: 4em 0 6em;
    padding: 4rem 1rem;
}
.contact-box{
    display: flex;
    justify-content: space-evenly;
    margin-top: 4em;
}
.contact-box img{
    width: 200px;
    background: #fff;
}
/* Box One */
.box-one{
    background-color: #00000054;
    color: #fff;
    padding: 3em 4em;
    border: 1px solid rgb(131, 137, 255);
    border-radius: 10px;
    height: 17em;
    /* height: 21em;   Social Media  */
}
.box-one .box-title{
    text-align: -webkit-center;
    margin-bottom: 10px;
}
.box-title p{
    font-weight: 500;
    font-size: 18px;
    font-family: system-ui;
}
.box-title .line{
    width: 8em;
    background-color:#b07bff;
    height: 3px;
    margin-bottom: 2em;
}


#icons{
    margin-top: 2em;
    position: relative;
    text-align: center;
}
.social-icon11{
    margin-right: 0.7em;
    color: #fff;
    font-size: 25px;
}
#icon-i:hover{
    transform: translateY(-6px);
    transition-duration: 0.5s;
    color: #b07bff;
}

/* Box Social  */
.contacts-list {
    grid-template-columns: 1fr;
}
.contact-item {
    min-width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 1em
}
li {
    list-style: none;
}
.icon-box {
    position: relative;
    background: #333;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color:#fff;
}
.contact-info {
    max-width: calc(100% - 64px);
    width: calc(100% - 64px);
}
.contact-info a{
    text-decoration: none;
    color: #fff;
    transition: .2s all ease-in-out;
}
.contact-info a:hover{
    color: #b07bff;
}
.contact-title {
    color: gray;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 2px;
}

/* Box Two */
.box-two{
    display: grid;
    gap: 1em;
}
.box-two .qr-one img, .box-two .qr-two img{
    width: 130px;
}
.box-two p , .box-three p{
    text-align: center;
    color: rgb(131 137 255);
    font-size: 19px;
    font-weight: 500;
}
/* Box Three */
.box-three img{
    width: 200px;
    background: #fff;
}

/* Office Section */
#office{
    padding: 1em 0;
}
.office-section{
    margin: 4em 0 0;
    padding: 4rem 1rem 0;
}
#cards-office{
    padding: 4em 0;
    display: flex;
    justify-content: space-evenly;
}
#card-office a{
    color: #fff;
}
#card-office{
    width: 300px;
    border-radius: 40px 0 40px 0;
    border: 3px solid rgb(17 24 39 / 60%);
    transition: .3s all cubic-bezier(0.87, 0.22, 1, 1);
}
#card-office:hover {
    border: 3px solid rgb(131 137 255);
}
#card-office .card-head h4, #card-office .card-foot {
    text-align: center;
    font-weight: 700;
}
#card-office .card-body{
    align-self: center;
}
.card-body img{
    width: 200px;
    height: 150px;
    border-radius: 15px;
}


@media (max-width: 992px) {
    .contact-box {
        display: grid;
        justify-content: space-evenly;
        margin-top: 4em;
        gap: 3em;
    } 
    
    .box-two {
        display: flex;
        gap: 2em;
        justify-content: center;
    }
    
    .box-three{
        justify-self: center;
    }
}

