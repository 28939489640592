/* About */
#about{
    padding: 1em 0;
}
.about{
    color: #fff;
    margin: 4em auto;
    padding: 4rem 1rem;

}
#about-box{
    display: flex;
    margin-top: 5em;
}
.sentence-container {
    display: grid;
    grid-template-columns: 100px 1fr;
    align-items: center;
}
.side-line {
    height: 2px;
    background-color: #fff;
    margin-right: 1em;
}
h2{
    color: rgb(107, 112, 210);
    font-family: elmessiri-bold;
}
.about-title{
    font-size: 20px;
    line-height: 24px;
    margin-top: -2.3em;
    color: #9ca3af;
    font-family: auto;
}
.card{
    background-color: rgb(17 24 39 / 60%);
    color: #fff;
}
.img{
    border-radius: 50%;
}
#img{
    margin-left: 1em;
    margin-right: 1em;
    font-size: 31px;
}


@media (max-width: 472px) {
    #img{
        margin-right: .5em;
        font-size: 29px;
    }
}