/* Agents Section */
#project{
    padding: 1em 0;
}
.projects-section {
    margin-block-start: 8rem;
}
.projects-section #container{
    padding: 3em 0;
    text-align: center;
}
.projects-section #container p{
    color: #cecece;
    line-height: 65px;
    font-family: monospace;
    font-size: 21px;
}

/* Agents Img */
.projects-section .client-list {
    display: flex;
    padding: 0 8em;
}
.projects-section .client-list img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
}
.projects-section .client-list li {
    flex: 0 0 20%; 
    max-width: 20%;
}
.projects-section .client-list li img{
    transition: .3s all ease-in-out;
}
.projects-section .client-list li img:hover{
    transform: scale(1.2);
}

@media (max-width: 768px) {
    .client-list {
        padding: 2px !important;
        display: grid !important;
        grid-template-columns: 100px 100px;
        justify-content: space-evenly;
    }

    .client-list li {
        flex: 0 0 40%;
        max-width: 40%;
    }
}