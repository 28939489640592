/* Services Section */
#service{
    padding: 1em 0;
}
.services-section {
    margin: 4em auto;
    padding: 4rem 1rem;
}
.services-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    margin-top: 4em;
}
.services-card {
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 325px;
    transition: all .4s ease;
    color: #bfc5ca;
    border: 1px solid rgb(131, 137, 255);
    height: 20em;
}
.services-card:hover {
    box-shadow: inset 6px 6px 30px #4b4b4b;
}
.services-card .overflow{
    padding: 2em;
    overflow: auto;
}
.services-icon {
    color: #149ddd;
    font-size: 3rem;
    margin-bottom: 2rem;
}
.services-title {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    font-family: sans-serif;
    font-weight: 600;
}
.services-desc {
    font-size: 16px;
    font-family: auto;
}
.services-card .btn-box{
    text-align: center;
}
.services-card .btn{
    color: rgb(131 137 255);
    font-family: 'elmessiri-bold';
    font-size: 17px;
    border: 0;
}