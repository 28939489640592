/* Team Section */
#team{
    padding: 1em 0;
}
.team{
    margin: 4em auto;
    padding: 4rem 1rem;
}
.team-title{
    margin: 2em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.team-title .line{
    width: 10em;
    background-color: rgb(107, 112, 210);
    height: 3px;
    margin-bottom: 2em;
    align-self: center;
}
.team-title1{
    color: #E5E7EB;
    font-weight: 500;
    margin-bottom: 5px;
}
.team-p{
    color: #bfbfbf;
    line-height: 1.8;
    max-width: 65%;
    margin: 10px auto;
}
.team .card{
    width: 18em;
    border: 3px solid #333;
    margin: 1em 2em;
    padding: 2.3em 1rem 0 !important;
    box-shadow: 0px 0px 12px 0px #666464;
    transition: .5s all cubic-bezier(0.87, 0.22, 1, 1);
}
.team .card:hover{
    border: 3px solid rgb(131 137 255);
}
.team .card-body{
    text-align: center;
    margin: 2em 0;
    padding: 0;
}
.team .card-body .card-name{
    color: #fff;
    margin-bottom: 1em;
}
.team .card-title{
    color: rgb(131 137 255);
}
.team .card-link{
    direction: rtl;
}
.team #team-card{
    margin: 1em;
}
.team #card-team{
    background-color: #303844;
}
.team .card-text{
    color: rgb(174 183 198);
    font-family: monospace;
}
.team #team-two{
    justify-content: space-evenly;
}
.team-p a {
    position: relative;
    transition:  color 300ms cubic-bezier(0.25, 0.32, 0.85, 0.58);
    text-decoration: none;
    color: rgb(131 137 255);
    font-size: 21px;
}
.hover-effect::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -10px;
    width: 100%;
    height: 1.5px;
    background: #fff;
    transform: scaleX(0);
    transform-origin: left;
    transition: all 300ms ease-in-out;
}
.hover-effect:hover::after {
    transform-origin: right;
    transform: scaleX(1);
}

/* Social Icon */
.team .link{
    margin-right: 1em;
    font-size: 20px;
    color: #fff;
}
.team .link:hover{
    color: rgb(131 137 255);
    transition: 1s all;
}
.fa-solid:hover{
    color: rgb(131 137 255);
    transition: 1s all;
}
.team #card-team {
    position: relative;
}
.team .item {
    border-radius: 10px;
    height: 120px;
    width: auto;
    position: relative;
    transition: all 10s ease;
    overflow: hidden;
    align-self: center;
}
.team .item img {
    width: 140px;
    height: 100%;
    border: 3px solid;
    border-radius: 50%;
}

/* Team Images */
#carouselExample{
    width: 50em;
    border-radius: 10px;
}
.carousel-control-next, .carousel-control-prev{
    z-index: 0 !important;
}
.carousel-item img{
    border-radius: 15px;
    width: 100%;
}

@media (max-width: 770px) {
    .team-p{
        max-width: 100%;
    }
}