@import url('https://fonts.googleapis.com/css2?family=Amiri+Quran&family=Cairo&family=IBM+Plex+Serif:wght@100&family=Lateef:wght@500&family=Playpen+Sans:wght@300&family=Roboto:ital,wght@1,100&family=Young+Serif&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
html, body{
  --tw-bg-opacity: 1;
  background-color: #002146;
  box-sizing: border-box;
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  font-family: none;
}
#particles{
  height:100%;
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1s;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1s;
}
.ParticleBackground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.offcanvas-header {
  display: flex;
  justify-content: flex-end;
}
.button1 {
  position: relative;
  width: 6em;
  height: 6em;
  border: none;
  background: none;
}
#close{
  font-size: 2.4em;
  color: #957bda;
}
.blur-overlay {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: block;
  height: 100%;
  left: 0;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 97;
}
.navbar {
  background-color: #002146;
  gap: -12px;
  box-shadow: 0px 0px 20px 0px #212d4e;
  padding: 1em !important;
  position: fixed;
  z-index: 1;
  width: 100%;
  top: 0;
}

/* Navbar Logo */
.logo-item {
  min-width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
.logo-item .icon-box {
  background-color: #f6f4f4;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.icon-box img{
  width: 50px;
  height: 50px;
}
.logo-info {
  max-width: calc(100% - 64px);
  width: calc(100% - 64px);
  margin-top: 1em;
}
.logo-info .logo-link{
  color: #cacaca;
  font-family: 'elmessiri-bold';
}
.logo-title {
  color: rgb(255, 255, 255);
  margin-bottom: 2px;
}
.offcanvas{
  background-color: #002146;
  width: 60% !important;
}
.nav-item{
  margin-left: 1.5em;
}
.toggle-button {
  display: none; /* Initially hide the button on larger screens */
}
.navbar h3 a {
  color: #fff;
  font-size: calc(0.5vw + 20px);
}
.navbar h3 a:hover{
  text-decoration: none;
}
.navbar nav {
  display: flex;
  align-items: center;
  gap: 1.5em;
}
.nav-link:hover{
  color: rgb(131 137 255);
}
.nav-link:focus {
  color:  rgb(131 137 255) !important;
}
.navbar a {
  color: #b8b6b6;
  font-size: calc(0.35vw + 12px);
  padding: 5px;
  text-decoration: none;
}
.navbar-brand{
  font-family: 'toyor-aljanah';
}
.navbar a:hover {
  --tw-text-opacity: 1;
  color: rgb(131 137 255/var(--tw-text-opacity));
}
.nav-link{
  text-align: end;
}
.collapse{
  direction: rtl;
}
.navbar-nav{
  direction: ltr;
  margin: 1em auto;
  align-items: center;
  font-family: 'elmessiri-bold';
}
.navbar-toggler{
  border: 0;
  font-size: 1.7em;
  color: rgb(131 137 255);
}
.navbar-toggler:focus{
  border: 0;
}
.hamburger {
  cursor: pointer;
}

.hamburger input {
  display: none;
}

.hamburger svg {
  /* The size of the SVG defines the overall size */
  height: 3em;
  /* Define the transition for transforming the SVG */
  transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.leFadeIn { animation-name: leFadeIn }
@keyframes leFadeIn {
    from { opacity: 0 }
    to { opacity: 1 }
}

a{
  text-decoration: none;
}

.line {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 3;
  /* Define the transition for transforming the Stroke */
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
              stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line-top-bottom {
  stroke-dasharray: 12 63;
}

.hamburger input:checked + svg {
  transform: rotate(-45deg);
}

.hamburger input:checked + svg .line-top-bottom {
  stroke-dasharray: 20 300;
  stroke-dashoffset: -32.42;
}





.language-button {
  color: #fff;
  font-family: 'elmessiri-bold';
  /* font-size: 18px; */
  /* display: flex; */
  width: 100%;
  /* text-align: left; */
  /* padding: 4px 10px; */
  border: none;
  background: none;
}

.language-button img {
  margin-right: 8px;
}
.language-button img {
  margin-right: 8px;
}
.dropdown-toggle::after {
  display: contents !important;
}

/* select{
  border:none;
  padding: 10px 20px;
  border-radius:5px;
}

select:focus{
  outline:none;
} */

/* .dropdown-content li {
  background: #2f3238;
  border-radius: 0.5em;
}
.dropdown-content li:hover {
  background: #1d1f24;
}
.dropdown-content li a {
  display: block;
  padding: 0.7em 0.5em;
  color: #fff;
  margin: 0.1em 0;
  text-decoration: none;
} */



/* Home */
#home {
  margin: 6em auto;
  text-align: left;
  padding: 11em 35px;
  background-image: url(../assets/img/background.webp);
  height: 88vh;
  background-size: cover;
  background-attachment: scroll;
  background-position: top;
  width: 100%;
}

#home #title{
  background-repeat: no-repeat;
  background-position-y: center;
}
#home .p {
  color: rgb(131 137 255);
  padding-bottom: 0.3em;
}
#home .name {
  font-size: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: 'Amiri Quran', serif;
}
#home .name .img{
  height: 50px;
  width: 50px;
  background-color: #fff;
}
.home-info {
  max-width: calc(100% - 64px);
  width: calc(100% - 64px);
  margin-top: 1em;
}
.home-info .home-link{
  color: #cacaca;
  font-family: 'elmessiri-bold';
}
.home-title {
  color: rgb(255, 255, 255);
  margin-bottom: 8px;
}
#home .title {
  opacity: 0.7;
  font-weight: 600;
  padding: 1em 0;
  font-size: 18px;
  color: #fff;
  font-family: none;
}
#home h2 {
  color: #ab81ff;
}
#home .button {
  width: 11.4em;
  position: relative;
  height: 3.5em;
  border: 3px ridge rgb(131 137 255);
  outline: none;
  background-color: transparent;
  color: white;
  transition: 1s;
  border-radius: 0.3em;
  font-size: 16px;
  font-weight: bold;
  color: rgb(131 137 255); 
  font-family: none;
}
#home .button::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 3%;
  width: 95%;
  height: 40%;
  transition: 0.5s;
  transform-origin: center;
}
#home .button::before {
  content: "";
  transform-origin: center;
  position: absolute;
  top: 80%;
  left: 3%;
  width: 95%;
  height: 40%;
  transition: 0.5s;
}
#home .button:hover::before, #home .button:hover::after {
  transform: scale(0)
}
#home .button:hover {
  box-shadow: inset 0px 0px 25px rgb(131 219 255 / 37%);
}

/* Footer Section */
#footer{
  color: #957bda;
  padding-bottom: 1em;
}
#footer .row{
  margin-top: 2em;
}
#footer #foot-box{
  text-align: center;
}
#footer #foot-box p{
  margin-bottom: 2em;
}
#footer hr {
  border-width: 3px;
}
#footer .divider {
  border-top: 2px solid;
}
#footer .scrollBtn #up{
  background-color: #957bda;
  color: #002146;
  font-size: 20px;
}
#footer .top {
  display: flex;
  justify-content: center;
  line-height: 0;
  font-size: 1.15rem;
}
#footer a {
  letter-spacing: 1px;
  transition: color 300ms cubic-bezier(0.25, 0.32, 0.85, 0.58);
  position: relative;
  text-decoration: none;
  color: #fff;
}
#footer .scroll {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    align-items: baseline;
    margin-right: 10px;
}
#footer a:hover{
  color: #957bda;
}


/* Media Query */
@media (min-width: 1200px){ 
  .navbar-expand-lg .offcanvas {
      position: static !important;
      z-index: auto !important;
      flex-grow: 1 !important;
      width: auto!important;
      height: auto!important;
      visibility: visible!important;
      background-color: transparent!important;
      border: 0!important;
      transform: none!important;
      transition: none !important;
  }
}

@media (max-width: 1000px) {
  .toggle-button {
    display: block;
    margin: 10px 0;
  }
}

@media (max-width: 992px) {
  .social-icons::before,
  .social-icons::after {
    width: 34%;
  }

  .dropdown-menu {
    min-width: 150px;
  }
}

/* Add the media query for medium-sized screens (768px - 992px) */
@media (max-width: 768px) {
  .social-icons::before,
  .social-icons::after {
    width: 24%;
  }
}

/* Add the media query for smaller screens (less than 768px) */
@media (max-width: 768px) {
  /* Adjust the dimensions and styles of other elements here if needed */
  .name {
    font-size: 6vw;
  }

  .title {
    font-size: 5vw;
  }
}

@media (max-width: 675px) {
  .navbar {
      flex-direction: column;
  }
  .name{
    font-size: 2em !important;
  }
}

@media (max-width: 612px) {
  #home .name {
    display: flex;
    align-items: center;
    font-size: 18px !important;
  }
  #home .name .img {
    height: 50px;
    width: 50px;
  }
  #home .name span{
    display: contents;
  }
}

@media (max-width: 480px) {
  .navbar {
      flex-direction: column;
  }
  .hero-section .text-container {
      width: 99%;
      padding: 0 14px;
  }
}

@media (max-width: 425px) {
  .navbar {
      flex-direction: column;
  }
  .hero-section .text-container {
      width: 99%;
      padding: 0 14px;
  }
}

@media (max-width: 320px) {
  .navbar {
      flex-direction: column;
  }
  .hero-section .text-container {
      width: 99%;
      padding: 0 14px;
  }
}